import React, { useState, useEffect, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import FullScreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullScreenIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from '@mui/icons-material/Close';

import { alpha } from "@mui/material/styles";

const PREFIX = 'FullScreenWrapper';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    centeredContent: `${PREFIX}-centeredContent`,
    fullScreenButton: `${PREFIX}-fullScreenButton`,
    fullScreenButtonFull: `${PREFIX}-fullScreenButtonFull`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.fullscreen}`]: {
        width: '100%',
        height: '100%',
        // geändert von absolut zu fixed weil sich sons die positionierung auf imageslider bezieht
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 65530,
        backgroundColor: '#373737',
        maxHeight: '100vh',
        display: 'flex',
        // direction hat hier gefehlt sonst funktioniert centered content nicht
        flexDirection: 'column',
    },

    [`& .${classes.centeredContent}`]: {
        alignSelf: 'center',
        position: 'relative',
        overflowY: 'hidden',
        width: '100%',
    },

    [`& .${classes.fullScreenButton}`]: {
        position: 'absolute',
        right: 5,
        top: 5,
        zIndex: 15535,
        color: '#fff',
        background: 'rgb(0 0 0 / 34%)',
        padding: 6,
        boxSizing: 'content-box',
        borderRadius: '100%',
        display:'none',
    },

    [`& .${classes.fullScreenButtonFull}`]: {
        top: 7,
        right: 7,
        color: '#fff',
        backgroundColor: 'rgb(0 0 0 / 34%)',
        padding: 5,
        boxSizing: 'content-box',
        borderRadius: '100%',
        display:'none',
    }
}));

const ENABLE_NATIVE_FULLSCREEN_API = false;

const FullScreenWrapper = React.forwardRef(({ component, fullscreenMode, setFullscreenMode, children, hideButton, buttonStyle, showFullscreenButton=false /* TODO */ }, ref) => {
    const [isFullscreen, setIsFullscreen] = useState(fullscreenMode || false);

    const fullscreenToggle = useCallback(() => {
        setIsFullscreen(!isFullscreen);
    }, [setIsFullscreen, isFullscreen]);

    useEffect(() => {
        setFullscreenMode(isFullscreen);
    }, [isFullscreen]);

    const fullscreenHandle = useFullScreenHandle();//TODO - remove if fullscreen-api not used
    useEffect(() => {
        if (ENABLE_NATIVE_FULLSCREEN_API && document.fullscreenEnabled) {
            if (isFullscreen) fullscreenHandle.enter();
            else fullscreenHandle.exit();
        }
    }, [isFullscreen]);

    const [FullscreenButton, setFullscreenButton] = useState(FullScreenIcon);
    useEffect(() => {
        if (component.fullscreen || !showFullscreenButton) {
            setFullscreenButton('div');
        } else {
            if (isFullscreen) {
                setFullscreenButton(CloseIcon);
                document.body.style.overflow = "hidden";
                window.scrollTo(0, 0);
            } else {
                setFullscreenButton(FullScreenIcon);
                document.body.style.overflow = "auto";
            }
        }
    }, [isFullscreen]);

    const FullscreenElement = ENABLE_NATIVE_FULLSCREEN_API ? FullScreen : 'div';//isFullscreen ? 'div' : 'div';//TODO - fullscreen api?



    const fullscreenElementProps = ENABLE_NATIVE_FULLSCREEN_API ?
        {
            handle: fullscreenHandle,
            fullscreen: isFullscreen,
            open: true,
            className: isFullscreen ? `${classes.fullscreen}` : ''

        }
        :
        {
            className: isFullscreen ? `${classes.fullscreen}` : ''
        };

    return (<Root>
        <FullscreenElement className="FullscreenElement" {...fullscreenElementProps} ref={ref}>
            <div className={isFullscreen ? classes.centeredContent : ''}>
                {children}
{/* div entfernt weil sich fullscreenbutton in dem unpositioniertn div positioniert ? */}
                    {hideButton !== true &&
                        <FullscreenButton onClick={fullscreenToggle} style={buttonStyle}
                            className={`${classes.fullScreenButton} ${isFullscreen ? classes.fullScreenButtonFull : ''}`} />
                    }

            </div>
        </FullscreenElement>
        </Root>
    );
});

export default FullScreenWrapper;